





































































































import Question from "@/types/Question";
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import MarkdownItVue from "markdown-it-vue";
import Comment from "@/types/Comment";
import memberModule from "@/store/modules/members";
import { Member } from "@/types/member";
import CommmentItem from "@/components/task/Comment/CommentItem.vue";
import CommentForm from "@/components/task/Comment/CommentDetails.vue";
import userModule from "@/store/modules/user";
import QuestionDetails from "@/components/task/Question/QuestionDetails.vue";
import questionModule from "@/store/modules/questions";
import ConfirmModal from "@/components/utility/ConfirmModal.vue";

@Component({
  components: {
    MarkdownItVue,
    CommmentItem,
    CommentForm,
    QuestionDetails,
    ConfirmModal,
  },
})
export default class QuestionItem extends Vue {
  @Ref() readonly confirm!: ConfirmModal;
  @Prop() question!: Question;
  showFormEdit = false;

  get comments(): Comment[] {
    return this.question.comments;
  }

  get isSolved(): number {
    return this.question.solved_by ? 1 : 0;
  }

  get commsCount(): string {
    return this.question.count_comments;
  }

  get hasComments(): boolean {
    return this.comments.length > 0;
  }

  get author(): Member | undefined {
    if (!this.question) return undefined;
    return memberModule.getMember(this.question.user_id);
  }

  get isAuthor(): boolean {
    return userModule.user?.id == this.author?.id;
  }

  edit(): void {
    this.showFormEdit = !this.showFormEdit;
  }

  async remove(): Promise<void> {
    const reply = await this.confirm.open();
    if (reply) {
      try {
        await questionModule.deleteQuestion(this.question);
        this.$toast.success(this.$t("questions.delete").toString());
      } catch {
        this.$toast.error(this.$t("global.error-form").toString());
      }
    }
  }
}
