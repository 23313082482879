












import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class Reactions extends Vue {
  @Prop() type!: number;
  @Prop({ default: false }) isActive!: boolean;

  @Emit("click")
  reacts(): number {
    return this.type;
  }
}
