



















import Question from "@/types/Question";
import { Component, Vue } from "vue-property-decorator";
import questionModule from "@/store/modules/questions";
import QuestionItem from "@/components/task/Question/QuestionItem.vue";
import QuestionDetails from "@/components/task/Question/QuestionDetails.vue";
import taskModule from "@/store/modules/tasks";

@Component({
  components: {
    QuestionItem,
    QuestionDetails,
  },
})
export default class Questions extends Vue {
  get questions(): Question[] {
    return questionModule.questions;
  }

  get count(): number {
    return this.questions.length;
  }

  get taskId(): string {
    return taskModule.selectedId;
  }
}
