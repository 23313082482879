var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.task)?_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"header-task",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-subtitle-1 font-weight-light"},[_c('v-chip',{staticClass:"mr-1",attrs:{"color":_vm.subject.color,"label":"","small":"","dark":_vm.isTextDark,"to":{
                name: 'subjectDisplay',
                params: {
                  subject_id: _vm.subject.id,
                },
              }},domProps:{"textContent":_vm._s(_vm.subject.name)}}),_c('v-chip',{attrs:{"color":_vm.isDue ? 'success' : 'error',"outlined":"","label":"","small":""},domProps:{"textContent":_vm._s(
                _vm.isDue ? _vm.$t('task.states.open') : _vm.$t('task.states.close')
              )}}),_c('span',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.$t("task.createBy"))+" ")]),_c('v-avatar',{staticClass:"profile",attrs:{"color":"primary","size":"16"}},[(_vm.author.picture)?_c('v-img',{attrs:{"src":_vm.author.picture}}):_vm._e()],1),_vm._v(" "+_vm._s(_vm.author.firstname)+" "),_c('timeago',{attrs:{"datetime":_vm.task.created_at,"auto-update":60}}),(_vm.task.histories_list.length > 0)?_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-circle-small")]):_vm._e(),_c('history-list',{staticClass:"text-caption",attrs:{"histories":_vm.task.histories_list}})],1),_c('v-spacer'),_c('v-checkbox',{attrs:{"small":"","color":"primary","dense":"","hide-details":"","label":_vm.$t('task.finish.label')},on:{"click":_vm.finished},model:{value:(_vm.task.has_finished),callback:function ($$v) {_vm.$set(_vm.task, "has_finished", $$v)},expression:"task.has_finished"}})],1),_c('v-card-title',[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.task.title)+" "),(_vm.task.isPrivate)?_c('v-icon',[_vm._v("mdi-lock")]):_vm._e()],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","to":{ name: 'taskEdit' }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),(_vm.isAuthor)?_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":_vm.delTask}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1),_c('v-card-subtitle',[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.dueDate.format("dddd, LL")))+" ")],1),_c('v-card-text',[_c('markdown-it-vue',{staticClass:"md-body",attrs:{"content":_vm.task.description}})],1),(!_vm.task.isPrivate)?_c('v-card-actions',[_c('reactions',{attrs:{"task":_vm.task}})],1):_vm._e()],1)],1),(!_vm.task.isPrivate)?_c('v-col',{attrs:{"cols":"12"}},[_c('questions')],1):_vm._e(),_c('confirm-modal',{ref:"confirm"})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }