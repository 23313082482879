




























































































































import Comment from "@/types/Comment";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import memberModule from "@/store/modules/members";
import userModule from "@/store/modules/user";
import { Member } from "@/types/member";
import MarkdownItVue from "markdown-it-vue";
import Question from "@/types/Question";
import CommentForm from "@/components/task/Comment/CommentDetails.vue";
import questionModule from "@/store/modules/questions";
import QuestionForm from "@/types/QuestionForm";
import ConfirmModal from "@/components/utility/ConfirmModal.vue";

@Component({
  name: "CommentItem",
  components: {
    MarkdownItVue,
    CommentForm,
    ConfirmModal,
  },
})
export default class CommentItem extends Vue {
  @Prop() comment!: Comment;
  @Prop() question!: Question;
  @Prop({ default: false }) selected!: boolean;
  @Ref() readonly confirm!: ConfirmModal;
  loadingReply = false;

  showFormReply = false;
  showFormEdit = false;

  get author(): Member | undefined {
    if (!this.comment) return undefined;
    return memberModule.getMember(this.comment.user_id);
  }

  get isAuthor(): boolean {
    return this.comment.user_id == this.question.user_id;
  }

  get isQuestionAuthor(): boolean {
    if (!this.author) return false;
    return userModule.user?.id == this.question.user_id;
  }

  get isLoggedAuthor(): boolean {
    if (!this.author) return false;
    return userModule.user?.id == this.comment.user_id;
  }

  get isTeacher(): boolean {
    return this.author ? this.author.isTeacher : false;
  }

  get isSolver(): boolean {
    return this.question.solved_by?.id == this.comment.id;
  }

  replyTo(): void {
    this.showFormReply = !this.showFormReply;
  }

  edit(): void {
    this.showFormEdit = !this.showFormEdit;
  }

  async remove(): Promise<void> {
    const reply = await this.confirm.open();
    if (reply) {
      try {
        await questionModule.deleteComment(this.comment);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async markSolved(): Promise<void> {
    this.loadingReply = true;

    try {
      let questionForm = this.question as unknown as QuestionForm;
      questionForm.solved = this.isSolver ? "" : this.comment.id;
      await questionModule.upsertQuestion(questionForm);
      this.$toast.success(this.$t("questions.solved").toString());
    } catch (err) {
      console.log(err);
    }

    this.loadingReply = false;
  }
}
