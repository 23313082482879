





























import { Component, Vue, Prop } from "vue-property-decorator";
import memberModule from "@/store/modules/members";
import { Member } from "@/types/member";
import History from "@/types/History";
import moment from "moment";

@Component
export default class HistoryItem extends Vue {
  @Prop() history!: History;

  get author(): Member | undefined {
    return this.history && this.history.user_id
      ? memberModule.getMember(this.history.user_id.toString())
      : undefined;
  }

  get perfTimeAt(): string | undefined {
    return this.history
      ? moment(this.history?.performed_at).format("LT")
      : undefined;
  }

  get perfDateAt(): string | undefined {
    return this.history
      ? moment(this.history?.performed_at).format("Do MMMM")
      : undefined;
  }

  get isCreation(): boolean {
    return this.history && this.history.meta == null;
  }

  get color(): string {
    return this.isCreation ? "warning" : "primary";
  }
}
