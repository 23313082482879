


























































































import { Task } from "@/types/task";
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import taskModule from "@/store/modules/tasks";
import subjectModule from "@/store/modules/subjects";
import memberModule from "@/store/modules/members";
import userModule from "@/store/modules/user";
import moment, { Moment } from "moment";
import { Member } from "@/types/member";
import { Subject } from "@/types/subject";
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";
import TinyColor from "tinycolor2";
import Reactions from "@/components/task/Reactions.vue";
import Questions from "@/components/task/Question/Questions.vue";
import ConfirmModal from "@/components/utility/ConfirmModal.vue";
import HistoryList from "@/components/task/HistoryList.vue";

@Component({
  components: {
    MarkdownItVue,
    Reactions,
    Questions,
    ConfirmModal,
    HistoryList,
  },
})
export default class TaskDisplay extends Vue {
  @Ref() readonly confirm!: ConfirmModal;

  get task(): Task | undefined {
    return taskModule.getCurrentTask;
  }

  get dueDate(): Moment {
    return moment(this.task?.due_at);
  }

  get createdDate(): Moment {
    return moment(this.task?.created_at);
  }

  get isDue(): boolean {
    if (!this.task) return false;
    return moment().endOf("day").isBefore(this.dueDate.endOf("day"));
  }

  get author(): Member | undefined {
    if (!this.task) return undefined;
    return memberModule.getMember(this.task.user_id);
  }

  get isAuthor(): boolean {
    return userModule.user?.id == this.author?.id;
  }

  get subject(): Subject | undefined {
    if (!this.task) return undefined;
    return subjectModule.getSubject(this.task.subject_id);
  }

  get isTextDark(): boolean {
    const color = new TinyColor(this.subject?.color);
    return color.getLuminance() < 0.228;
  }

  async delTask(): Promise<void> {
    const reply = await this.confirm.open();
    if (reply) {
      if (!this.task) return;
      try {
        await taskModule.delete(this.task);
        this.$router.push({ name: "tasks" });
        this.$toast.success(this.$t("global.success").toString());
      } catch (err) {
        this.$toast.error(this.$t("global.errors.unknown").toString());
      }
    }
  }

  mounted(): void {
    this.loadTask();
  }

  @Watch("$route.params.task_id")
  updateTask(): void {
    this.loadTask();
  }

  async loadTask(): Promise<void> {
    try {
      const taskId = this.$route.params.task_id;
      await taskModule.selectTask(taskId);
    } catch (err) {
      if (err.response.status == 404) {
        this.$router.replace({ name: "NotFound" });
      }
    }
  }

  async finished(): Promise<void> {
    if (!this.task) return;

    const data = {
      task_id: this.task.id,
      hasFinished: this.task.has_finished,
    };
    try {
      await taskModule.setFinish(data);
      this.$toast.success(this.$t("global.success").toString());
    } catch (err) {
      this.$toast.error(this.$t("global.errors.unknown").toString());
    }
  }
}
