
























































import { Component, Vue, Prop } from "vue-property-decorator";
import Reaction from "@/types/Reaction";
import { Task } from "@/types/task";
import ReactionItem from "@/components/task/ReactionItem.vue";
import taskModule from "@/store/modules/tasks";

@Component({
  components: {
    ReactionItem,
  },
})
export default class Reactions extends Vue {
  @Prop() task!: Task;
  @Prop({ default: false }) dense!: boolean;

  get reactions(): Reaction[] {
    return this.task.reactions_list;
  }

  get activeReactions(): number[] {
    return this.reactions
      .filter((item) => item.hasReact)
      .map((item) => item.type);
  }

  isIncluded(type: number): boolean {
    return this.activeReactions.includes(type);
  }

  react(type: number): void {
    const taskId: string = this.task.id;
    taskModule.postReaction({ taskId, type });
  }
}
