
























import { Component, Vue, Prop } from "vue-property-decorator";
import HistoryItem from "@/components/task/HistoryItem.vue";

@Component({
  components: {
    HistoryItem,
  },
})
export default class HistoryList extends Vue {
  @Prop() histories!: History[];
  @Prop({ default: true }) separator!: boolean;

  get hasHistories(): boolean {
    return this.histories.length > 0;
  }
}
